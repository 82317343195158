import initializationApi from '@/api/initialization';
import i18n from '@/plugins/vue-i18n';
import { licenseStatuses, UNLIM_USERS_COUNT, accountPlans } from '@/config/license';
import { addDaysToDate } from '@/helpers/date/addDaysToDate';
import { dateByLocaleKey, localeDateKeys } from '@/helpers/locale/localeDate';

const types = {
  SET_INITIALIZING: 'SET_INITIALIZING',
  SET_PROJECT_STATUS: 'SET_PROJECT_STATUS',
  SET_LICENSE_DETAILS: 'SET_LICENSE_DETAILS',
  SET_TENANT_USERS: 'SET_TENANT_USERS'
};

const state = () => ({
  initializing: false,
  hasProject: false,
  licenseDetails: null,
  tenantUsers: null
});

const getters = {
  // =========== license info =============== //
  expirationDate: (state) => {
    // date including expiration date
    return addDaysToDate(state.licenseDetails?.expirationDate, 1);
  },
  gracePeriodDays: state => +state.licenseDetails?.gracePeriodDays || 0,
  currentGraceDay: (state, getters) => {
    const diff = getters.gracePeriodDays - +state.licenseDetails?.remainGracePeriod;

    if (diff <= 0) {
      return getters.gracePeriodDays;
    }

    return getters.gracePeriodDays - diff;
  },
  expiresIn: state => {
    if (state.licenseDetails?.expiresIn === null) {
      return 1;
    }

    const remain = +state.licenseDetails?.expiresIn;

    return remain > 0 ? remain : 0;
  },
  isExpired: state => !!state.licenseDetails
    && state.licenseDetails.expiresIn !== null
    && +state.licenseDetails.expiresIn <= 0,
  isGracePeriodFinished: (state, getters) => {
    return getters.isExpired && +state.licenseDetails?.remainGracePeriod < 0;
  },
  expirationLocaleDate: (state) => {
    return dateByLocaleKey(state.licenseDetails?.expirationDate, localeDateKeys.YMD);
  },
  needLicenseDetails: state => state.licenseDetails?.licenseStatus !== licenseStatuses.OK,
  isFreePlan: (state) => state.licenseDetails?.accountPlan === accountPlans.FREE,
  expireDetails: (_, getters) => {
    if (getters.isExpired) {
      return i18n.tc('Web.LicenseDetails.InfoExpired');
    }

    return i18n.tc('Web.LicenseDetails.InfoExpiresIn.val', {
      p1: this.$tc('Web.LicenseDetails.InfoExpiresIn.val.p1', getters.expiresIn, { n: getters.expiresIn })
    });
  },
  increaseMaxUsersLimitEnabled: state => state.licenseDetails?.increaseMaxUsersLimitEnabled,
  currentUsersCount: state => +state.licenseDetails?.currentUsersAmount,
  maxUsersCount: (state) => +state.licenseDetails?.maxUsersAmount || UNLIM_USERS_COUNT,
  tenantUsers: (state) => state.tenantUsers.filter(user => user.id)
};

const mutations = {
  [types.SET_INITIALIZING](state, value) {
    state.initializing = value;
  },
  [types.SET_PROJECT_STATUS](state, value) {
    state.hasProject = value;
  },
  [types.SET_LICENSE_DETAILS](state, value) {
    state.licenseDetails = value;
  },
  [types.SET_TENANT_USERS](state, value) {
    state.tenantUsers = value;
  }
};

const actions = {
  setInitializing({ commit }, value) {
    commit(types.SET_INITIALIZING, value);
  },
  async getProjectStatus({ commit }) {
    const response = await initializationApi.getProjectStatus();
    const data = response?.data;

    if (!data) {
      throw new Error('Server is down');
    }

    commit(types.SET_PROJECT_STATUS, data?.hasAvailableProject);

    return response;
  },
  async getSubscriptionDetails({ commit }) {
    try {
      const response = await initializationApi.getSubscriptionDetails();
      const licenseDetails = response?.data;

      if (licenseDetails) {
        commit(types.SET_LICENSE_DETAILS, licenseDetails);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'getSubscriptionDetails' });

      throw e;
    }
  },
  async getTenantUsers({ commit }) {
    try {
      const response = await initializationApi.getTenantUsers();
      const data = response?.data;

      if (data) {
        commit(types.SET_TENANT_USERS, data?.users);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'getTenantUsers' });

      throw e;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
